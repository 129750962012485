<template>
  <div>
    <div class="row row-sm">
      <div class="col-3">
        <label for="">Client <span class="text-danger">*</span> </label>
        <select
          v-model="filter.customer_reference"
          class="form-select"
          required
        >
          <option selected value="">--</option>
          <option
            v-for="(customer, index) in customers"
            :key="index++"
            :value="customer.reference"
            :style="
              customer.blocked || customer.fullName.includes('Bloqué')
                ? { color: 'red' }
                : ''
            "
          >
            {{ customer.fullName }}
          </option>
        </select>
      </div>

      <div class="col">
        <label for="">Du <span class="text-danger">*</span> </label>
        <input v-model="filter.startDate" type="date" class="form-control" />
      </div>
      <div class="col">
        <label for=""> Au <span class="text-danger">*</span> </label>
        <input v-model="filter.endDate" type="date" class="form-control" />
      </div>

      <div class="col-auto">
        <label for=""> </label>
        <button @click="toFilter(filter)" class="btn btn-info">
          <i class="bi bi-search"></i> Rechercher
        </button>
      </div>

      <div class="col-auto">
        <label for=""> </label>
        <button @click="print(filter)" class="btn btn-secondary">
          <i class="bi bi-printer"></i> Imprimer
        </button>
      </div>
    </div>
    <br />
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn btn-info">
          <i class="bi bi-arrow-clockwise"></i>
          Actualiser
        </button>
      </div>
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'payments-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer un nouveau paiement
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence</th>
          <th scope="col">Client</th>
          <th scope="col">Date</th>
          <th scope="col">M. Facture</th>
          <th scope="col">Mode</th>
          <th scope="col">M. Payé</th>
          <th scope="col">M. Impayé</th>
          <th scope="col">Remarque</th>
          <th scope="col">Créer par</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(payment, index) in payments" :key="index++">
          <td>
            {{ index }}
          </td>
          <td>
            {{ payment.reference }}
          </td>
          <td>
            <span
              v-if="payment.customer"
              :class="
                payment.customer.deleted_at
                  ? 'text-danger text-decoration-line-through'
                  : ''
              "
            >
              {{ payment.customer.fullName }}
            </span>
          </td>
          <td>{{ payment.date }}</td>

          <td class="er">
            <span v-if="payment.sale">
              <span v-if="payment.sale.totalPriceTTC >= 0">
                {{ payment.sale.totalPriceTTC }}
              </span>
            </span>
          </td>
          <td class="text-primary">
            <span>
              {{ payment.paymentMethod }}
            </span>
          </td>
          <td class="text-success fw-bold">
            <span v-if="payment.amount >= 0">
              {{ payment.amount }}
            </span>
          </td>
          <td class="text-danger">
            <span v-if="payment.remainingAmount >= 0">
              {{ payment.remainingAmount }}
            </span>
          </td>
          <td>
            {{ payment.remark }}
          </td>
          <td>
            <span v-if="payment.user">
              {{ payment.user.name }}
            </span>
          </td>
          <td v-if="userRole == 'Caissier'" class="p-0 m-0">
            <button
              @click="
                $router.push({
                  name: 'cashier-payments-details',
                  params: { reference: payment.reference },
                })
              "
              class="btn"
            >
              <i class="bi bi-info-square fs-5"></i>
            </button>
          </td>

          <td v-else-if="userRole == 'Admin'" class="p-0 m-0">
            <button
              @click="
                $router.push({
                  name: 'payments-details',
                  params: { reference: payment.reference },
                })
              "
              class="btn"
            >
              <i class="bi bi-info-square fs-5"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
      filter: {
        startDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
        endDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
      },
    };
  },
  computed: {
    ...mapGetters("payment", {
      payments: "getAll",
    }),
    ...mapGetters("customer", {
      customers: "getAll",
    }),
    ...mapGetters("auth", {
      userRole: "getUserRole",
    }),
  },
  mounted() {
    this.$store.dispatch("payment/getAll");
    this.$store.dispatch("customer/getAll");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("payment/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("payment/getAll");
    },

    async listPayments(checkbox) {
      if (checkbox == true) {
        console.log(checkbox);
        return await this.$store.commit("payment/getTodays");
      } else {
        return await this.$store.dispatch("payment/getPayments");
      }
    },
    async sort(value) {
      return await this.$store.commit("payment/sort", value);
    },
    async toFilter(data) {
      return await this.$store.dispatch("payment/filter", data);
    },
    async print(data) {
      await this.$store.dispatch("payment/print", data);
    },
  },
};
</script>
